import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { SelectedPaymentOption } from '../../components/ChallengesPage/Widget/components/Pricing/interfaces';

import { getParticipant } from './helpers/getParticipant';
import { joinToChallenge } from './helpers/joinUserToChallenge';
import { cancelInvite } from './helpers/cancelInvite';
import { leaveTheChallenge } from './helpers/leaveTheChallenge';

import {
  getUserEmail,
  getUserFromConfig,
  getUserType,
  promptLogin,
  UpdatedUserData,
  updateUserContext,
} from './helpers/userContextHelpers';
import { State as ParticipantState } from '@wix/ambassador-challenges-v1-participant/types';

import memoize from 'lodash/memoize';
import cloneDeep from 'lodash/cloneDeep';
import { IUserProviderProps } from './UserProvider';
import { isParticipantInLockedState } from './helpers/getStats';
import { leaveProgram } from './helpers/leaveProgram';
import { isMA } from '../../selectors/isMA';
import { isUserJoinedAlready } from './helpers/userTypeHandlers';
import handleUserAfterLogin from './helpers/handleUserAfterLogin';
import { isParticipantV3enabled } from '../../experiments/isV3enabled';
import { requestParticipantV3ForChallenge } from '../storage-contexts/Challenge/api/requestParticipantV3';
import { incrementProgress } from '../../selectors/participant/incrementProgress';
import { resolveProgramId } from '../storage-contexts/Challenge';

// `getParticipant` should be called only for participant-related pages, but other methods can be used at any page (f.ex. MA).
export const userProviderPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
  opts?: { dontRequestParticipant?: boolean },
): Promise<IUserProviderProps> {
  const user = getUserFromConfig(flowAPI.controllerConfig);
  const participantRequests = [];
  if (!opts?.dontRequestParticipant) {
    const slug = (await resolveProgramId(flowAPI)).programId;
    participantRequests.push(getParticipant(flowAPI));
    if (isParticipantV3enabled(flowAPI)) {
      participantRequests.push(requestParticipantV3ForChallenge(slug, flowAPI));
    }
  }
  const [participant, participantV3, participantInLockedStateResponse] =
    await Promise.all([
      ...participantRequests,
      isParticipantInLockedState(flowAPI),
    ]);
  const userType = getUserType(user, participant);

  void handleUserAfterLogin();

  const userProviderProps: IUserProviderProps = {
    user,
    userType,
    isParticipantInSuspendedState:
      getUserType(user, participant) === ParticipantState.SUSPENDED,
    isParticipantInLockedState:
      flowAPI.environment?.isViewer && isUserJoinedAlready(userType)
        ? participantInLockedStateResponse
        : false,
    participant,
    participantV3,
    async promptLogin(): Promise<any> {
      return promptLogin(flowAPI);
    },
    async join(
      selectedPaymentOption: SelectedPaymentOption,
      startDate?: string,
      settings?: { showOneAppInfo?: boolean },
    ): Promise<void> {
      void joinToChallenge(
        flowAPI,
        userProviderProps,
        selectedPaymentOption,
        startDate,
        settings,
      );
    },
    async cancelJoinRequest(challengeId?: string): Promise<void> {
      await cancelInvite(flowAPI, challengeId);
      if (!isMA(flowAPI)) {
        await userProviderProps.updateParticipant();
      }
    },
    async leaveProgram(
      participantId: string,
      challengeId: string,
    ): Promise<void> {
      return leaveProgram(flowAPI, participantId, challengeId);
    },
    async leaveTheChallenge(
      participantId: string,
      challengeId?: string,
    ): Promise<void> {
      return leaveTheChallenge(
        flowAPI,
        participantId,
        userProviderProps,
        challengeId,
      );
    },
    async incrementParticipantsCompletedStepSummary(): Promise<UpdatedUserData> {
      const participantCopy = cloneDeep(userProviderProps.participant);

      return userProviderProps.updateParticipant(
        incrementProgress(participantCopy),
      );
    },
    async updateParticipant(newParticipant): Promise<UpdatedUserData> {
      const userData: UpdatedUserData = await updateUserContext(
        flowAPI,
        newParticipant,
      );

      Object.entries(userData).forEach(([key, val]) => {
        userProviderProps[key] = val;
      });

      flowAPI.controllerConfig.setProps(userData);

      return userData;
    },
    async updateParticipantLockedState(): Promise<void> {
      const userType = userProviderProps.userType;

      flowAPI.controllerConfig.setProps({
        isParticipantInLockedState:
          flowAPI.environment?.isViewer && isUserJoinedAlready(userType)
            ? await isParticipantInLockedState(flowAPI)
            : false,
      });
    },
    async requestMemberEmail() {
      const memberEmail = await getUserEmail(flowAPI.controllerConfig);

      flowAPI.controllerConfig.setProps({
        user: {
          ...user,
          email: memberEmail,
        },
      });
    },
  };

  return userProviderProps;
});
